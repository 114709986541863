import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect } from "react";
import WOW from "wow.js";
import HomeTwo from "./pages/Home/HomeTwo";
import HomeThree from "./pages/Home/HomeThree";
import HomeFour from "./pages/Home/HomeFour";
import HomeFive from "./pages/Home/HomeFive"
import Blog from "./pages/Blog/Blog";
import BlogDetailsPage from "./pages/BlogDetails/BlogDetailsPage";
import { useLocation } from "react-router-dom";
import CountDownTwo from "./components/CountDown/CountDownTwo";
import ArticlePage from "./pages/ArticlePage/ArticlePage";
import ExternalRedirect from "./ExternalRedirect";
import WhitepaperApp from "./pages/WhitepaperApp/Whitepaper"

function App() {
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: false,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route  path="/" element={<HomeFive />} />
        <Route path="home-two" element={<HomeTwo />} />
        <Route path="home-three" element={<HomeThree />} />
        <Route path="home-four" element={<HomeFour />} />
        <Route path="home-five" element={<HomeFive />} />
        <Route path="countdown-two" element={<CountDownTwo />} />
        <Route path="articlepage" element={<ArticlePage />} />
        <Route path="wpapp" element={<WhitepaperApp />} />

        {/* <Route path="slider-banner" element={<HomeFive />} /> */}
        <Route path="blog" element={<Blog />} />
        <Route path="blog-details" element={<BlogDetailsPage />} />
        {/* <Route path="/whitepaper" element={<Navigate to="https://whitepaper.infinaeon.com/whitepaper" replace />} /> */}
        <Route path="whitepaper"  element={<ExternalRedirect url="https://whitepaper.infinaeon.com/whitepaper" />} />
      </Routes>
    </>
  );
}

export default App;
