import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ExternalRedirect({ url }) {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <p>Redirecting...</p>;
}

export default ExternalRedirect;