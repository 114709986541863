import react from "react";
import HeaderOne from "../../components/Header/HeaderOne";
import ArticlePageItems from "../../components/ArticlePageItems/ArticlePageItems"


const ArticlePage = () => {
    return (
      // <LayoutOne>
     
        
        <main className="fix">
          <div className="bg-3"></div>
         
          {/* <div className="vhheight"> */}
       
      <HeaderOne />
      <ArticlePageItems />

      
      </main>

    );
};

export default ArticlePage;