import React, { useState } from "react";
import { Container, Row, Col, Nav, Form } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import ReactMarkdown from 'react-markdown';
import LpfLogo from "../../assets/img/images/LunarPumpFun - Logo.png";

const Sidebar = ({ sections, activeSection, setActiveSection, searchQuery, setSearchQuery }) => {
  // Recursive function to render nav links with proper indentation
  const renderNavLinks = (items, level = 0) => {
    return items.map((item) => (
      <div key={item.id} className="overflowhidden"> 
        <Nav.Link
          href={`#${item.id}`}
          active={activeSection === item.id}
          onClick={() => setActiveSection(item.id)}
          style={{
            cursor: "pointer",
            color: activeSection === item.id ? "white" : "#209b81",
            // fontWeight: activeSection === item.id ? "bold" : "normal",
            paddingLeft: `${10 + level * 15}px` // Increase indentation for deeper nesting
          }}
        >
          {item.title}
        </Nav.Link>
        {/* Recursively render nested subsections */}
        {item.subsections && renderNavLinks(item.subsections, level + 1)}
      </div>
    ));
  };

  return (
    <div className="bg-black sidebar-container p-3 border-end overflowhidden">
      <h5 className="mb-3">
        <img src={LpfLogo} className="lpflogo" alt="Logo" />
        {/* <span>Docs</span> */}
      </h5>
      {/* <Form.Control
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="mb-3"
      /> */}
      <Nav className="flex-column">{renderNavLinks(sections)}</Nav>
    </div>
  );
};

const ContentSection = ({ id, title, content, content2, content3, content4, content5, content6, content7, isOpen }) => {
  return (
    <CSSTransition in={isOpen} timeout={300} classNames="fade" unmountOnExit>
      <section id={id} className="mb-4 contentmain overflowhidden">
      <div className="contentw">
        <h4>{title}</h4>
        <br></br>
     
        <p>{content}</p> 
        <p>{content2}</p>
        <p>{content3}</p>
        <p>{content4}</p>
        <p>{content5}</p>
        <p>{content6}</p>
        <p>{content7}</p>
        </div>
          
      </section>
    </CSSTransition>
  );
};

const Content = ({ activeSection, sections }) => {
  // Flatten all sections and subsections to search for the active section
  const allSections = sections.flatMap((section) => [
    section,
    ...(section.subsections || []).flatMap((sub) => [sub, ...(sub.subsections || [])])
  ]);

  const activeContent = allSections.find((section) => section.id === activeSection);

  return (
    <div className="p-4">
      {activeContent && <ContentSection id={activeContent.id} title={activeContent.title} content={activeContent.content}  content2={activeContent.content2} content3={activeContent.content3} content4={activeContent.content4} content5={activeContent.content5} content6={activeContent.content6} content7={activeContent.content7} isOpen />}
    </div>
  );
};

export default function App() {
  const [searchQuery, setSearchQuery] = useState("");
  const [darkMode, setDarkMode] = useState(true);
  const [activeSection, setActiveSection] = useState("introduction");

  const sections = [
    {
      id: "introduction",
      title: "Overview",
      content: "Lunar Pump is an innovative decentralized platform designed to revolutionize the token launch process. Serving as a top EVM token launchpad, it provides a fair, transparent, and incentivized ecosystem for developers and users. Lunar Pump will facilitate token launches without presales or team allocations, and will employ an innovative bonding mechanism to ensure liquidity while rewarding both developers and users."
    },
    {
      id: "features",
      title: "Key Features",
      content: "Lunar Pump is committed to fairness. Every token launched on the platform is free of presales and team allocations, ensuring a level playing field for all participants.",
      subsections: [
        {
          id: "feature1",
          title: "Liquidity Pool (LP) Burn Mechanism",
          content: "LP tokens will be burned on all token launches to maintain scarcity and ensure sustainability of liquidity. This burn mechanism ensures that the liquidity pool is always refreshed and free from manipulation.",
         
        },
        {
          id: "feature2",
          title: "Supported Chains",
          content: (
            <>
        <b> V1:&nbsp; </b> Infinaeon, BNB, and Base chains
            </>
          ),
          
          content2: <>
          <b>V2 and Beyond:&nbsp;</b> AVAX, Arbitrum, Matic, and other selected chainsAs the platform expands, more EVM-compatible chains will be integrated to offer greater flexibility and reach to token developers.</>,
 content3: "As the platform expands, more EVM-compatible chains will be integrated to offer greater flexibility and reach to token developers.",
          // subsections: [
          //   {
          //     id: "burn_benefits",
          //     title: "1. Benefits of LP Burn",
          //     content: "Burning LP tokens ensures a sustainable liquidity pool and prevents rug pulls."
          //   },
          //   {
          //     id: "burn_process",
          //     title: "2. How LP Burn Works",
          //     content: "LP tokens are permanently removed, reducing circulating supply."
          //   }
          // ]
        },
        {
          id: "feature3",
          title: "Platform Fees",
          content: "Lunar Pump will charge a 1-2% volume fee, applicable to all platform transactions (buy/sell volume). Additionally, a 20% bonding fee will be applied to each bonding event."
        }
       
      ]
    },
    {
      id: "api",
      title: "Bonding Mechanism",
      content: "Lunar Pump is committed to fairness. Every token launched on the platform is free of presales and team allocations, ensuring a level playing field for all participants.",
      content2: <>
      <b>Infinaeon & Base Chains:&nbsp;</b>Bonding occurs when the LP hits 4.2 ETH 3.36 ETH sent to Uniswap (Base) or Infinity Swap (Infinaeon) 0.84 ETH kept for the platform
      
      </>,
      content3: <>
       <b>BNB Chain:&nbsp;</b> Bonding occurs when the LP hits 17.5 BNB 14 BNB sent to PancakeSwap 3.5 BNB kept for the platform</>,

      // subsections: [
      //   {
      //     id: "feature1",
      //     title: "Liquidity Pool (LP) Burn Mechanism",
      //     content: "LP tokens will be burned on all token launches to maintain scarcity and ensure sustainability of liquidity. This burn mechanism ensures that the liquidity pool is always refreshed and free from manipulation.",
         
      //   },
      //  ]
      },
    
   
   
    {
      id: "faq",
      title: "Developer Rewards",
      content: "Lunar Pump offers a unique incentive structure for developers. For the first 30 days after Lunar Pump goes live, 50% of the bonding fee is distributed to the developer's wallet. This incentivizes developers to launch and bond their tokens on the platform, contributing to both short-term and long-term success.",
      content2: <>
      <b>Developer Rewards:&nbsp;</b>
      <br></br>
      Infinaeon & Base: 0.42 ETH 
      <br>
      </br>BNB: 1.25 BNB
      </>,
      content3: "Developers are free to use these rewards however they see fit, allowing for maximum flexibility and profit potential.",

    
    },
    {
      id: "userrewards",
      title: "User Rewards",
      content: "Lunar Pump values its user base. The top users of the platform, who actively contribute to its success, will be rewarded in the form of: ETH, BNB, or Tokens.",
      content2: "These rewards are distributed based on user activity and contributions to the platform's ecosystem. Users will be recognized for their engagement, helping to drive the platform's success and creating an additional incentive for long-term participation.",
  

    
    },

    {
      id: "futurefeatures",
      title: "Future Features",
      content: "While the MVP (Minimum Viable Product) is already feature-rich, Lunar Pump plans to expand and improve upon its offerings with the following features:",
      content3: <>
      <b>Additional EVM Chains:&nbsp;</b> More chains will be integrated to enhance the platform’s accessibility and enable token launches across diverse ecosystems.</>,
      content4: <>
      <b>Staking:&nbsp;</b> Lunar Pump will introduce staking mechanisms, allowing users to earn passive income by staking their tokens on the platform.
      </>,
      content5: 
      <>
      <b>User Leaderboard:&nbsp; </b>A leaderboard will highlight top users, creating a gamified experience and incentivizing users to remain engaged.
      </>,
      
      content6: 
      
      <>
      <b>Booster Options:&nbsp; </b>Special booster options will be available to help developers enhance the launch process and provide additional opportunities for growth.
      </>,
      
      content7: 
      
      <>
      <b>Exclusive Feature (Coming Soon):&nbsp; </b>Lunar Pump’s flagship feature, which will be unveiled In Version 2.0, promises to distinguish the platform from others in the space. This will be one of the first true fair launch mechanisms, setting Lunar Pump apart in the competitive landscape of token launchpads.
      </>,
      
    
    },
    {
      id: "whylpf",
      title: "Why Lunar Pump?",
      content: "Lunar Pump offers a platform that fosters fairness, transparency, and developer and user empowerment. By eliminating presales and team allocations and incorporating the LP burn mechanism, Lunar Pump ensures that all token launches are equitable and sustainable. With its unique bonding system, rewards structure, and incentives for both developers and users, Lunar Pump creates a truly decentralized ecosystem.",
      content3: "For developers, the 50% bonding fee reward for the first 30 days creates a strong incentive to launch and bond tokens on Lunar Pump, guaranteeing they remain engaged and profitable throughout their token's lifecycle. Meanwhile, users benefit from rewards, staking options, and a constantly evolving platform.",
      content4: "Lunar Pump is more than just a token launchpad; it's a community-driven platform designed to grow with its users and developers. Whether you’re a developer seeking to launch your token or a user looking for rewarding participation, Lunar Pump provides a fair, transparent, and highly incentivized environment.",
      content5: "With future developments like staking, new EVM chain integrations, and a unique flagship feature, Lunar Pump is poised to become the go-to launchpad in the blockchain ecosystem.",
      content6: "Stay tuned for more updates, and get ready for one of the most exciting launches in the decentralized space.",

    },

  ];

  // Filtered sections for search functionality
  const filteredSections = sections
    .map((section) => ({
      ...section,
      subsections: section.subsections
        ? section.subsections.filter(
            (sub) =>
              sub.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            sub.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
              sub.content2.toLowerCase().includes(searchQuery.toLowerCase())||
              sub.content3.toLowerCase().includes(searchQuery.toLowerCase())
          )
        : []
    }))
    .filter(
      (section) =>
        section.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        section.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
        // section.content2.toLowerCase().includes(searchQuery.toLowerCase()) ||
        section.subsections.length > 0
    );

  return (
    <div className={darkMode ? "bg-black text-white" : "bg-white text-dark"}>
      <Container fluid>
        <Row>
          <Col md={3} className={darkMode ? "bg-black" : "bg-light"}>
            <Sidebar
              sections={filteredSections}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </Col>
          <Col md={9}>
            <div className="d-flex justify-content-end p-3">
              {/* <Button variant={darkMode ? "light" : "dark"} onClick={() => setDarkMode(!darkMode)}>
                {darkMode ? "Light Mode" : "Dark Mode"}
              </Button> */}
            </div>
            <Content activeSection={activeSection} sections={filteredSections} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
