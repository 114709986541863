import React from "react";
import { motion } from "framer-motion";
import styles from "../../assets/css/articlepage.module.css";


type TableRowProps = {
  imageSrc: string;
  title: string;
  description: string;
  viewarticle: string;
};

const TableRow: React.FC<TableRowProps> = ({ imageSrc, title, description, viewarticle }) => {
  return (
    <motion.div
      className={`${styles.row} d-flex flex-column flex-md-row align-items-center mb-4`}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
    >
      <div className="me-md-3 mb-3 mb-md-0">
        <img
          src={imageSrc}
          alt={title}
          className="img-fluid rounded-circle shadow"
          style={{ width: "80px", height: "80px", objectFit: "cover" }}
        />
      </div>
      <div>
        <h3 className="fs-5 fw-bold mb-1">{title}</h3>
        <p className="text-muted mb-0">{description}</p>
        <p className="text-muted mb-0">{viewarticle}</p>
      </div>
    </motion.div>
  );
};

const ResponsiveTable: React.FC = () => {
  const data = [
    {
      imageSrc: "https://d3iuzwoiyg9qa8.cloudfront.net/webadmin/storage/public/crypto-news/August2024/lg1t2AU6nFyUCOsF2vbzYuSwFsVTiV2RhYXOUEdm.webp",
      title: "Neiro on ETH Price Crashes 40% as Investors Flock to Infinaeon Presale.",
      description: "CoinGabbar",
      viewarticle: "link"
    },
    {
      imageSrc: "/path/to/image2.jpg",
      title: "Row 2 Title",
      description: "This is a description for row 2.",
    },
    {
      imageSrc: "/path/to/image3.jpg",
      title: "Row 3 Title",
      description: "This is a description for row 3.",
    },
  ];

  return (
    <div className="container my-5">
      <h1 className="styles">Articles</h1>
      {data.map((row, index) => (
        <TableRow
          key={index}
          imageSrc={row.imageSrc}
          title={row.title}
          description={row.description}
          view={row.viewarticle}
        />
      ))}
    </div>
  );
};

export default ResponsiveTable;
