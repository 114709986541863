import React from "react";
import WpImage from "../../assets/img/images/wpslide3.png";
import PresaleMethods from "./PresaleMethods";

const ProblemVideo = () => {
  return (
    <section id="countdown" className="countdown-area-two">
      <div className="container custom-container-four">
        <div className="row">
          <div className="col-lg-12">
            <div className="countdown-wrap">
              
              {/* Image Container to Keep Everything Inside */}
              <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
                {/* Whitepaper Image */}
                <img 
                  src={WpImage} 
                  className="problemimg" 
                  style={{ width: "100%", height: "auto", display: "block" }} 
                  alt="Whitepaper"
                />

                {/* Container for Title and Buttons */}
                <div 
                  style={{
                    position: "absolute",
                    bottom: "10%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: "992px",
                    padding: "0 15px", // Adds padding on mobile for better fit
                   
                  }}
                >
                  {/* Title Above Buttons */}
                  <h2 
                    style={{
                      // background: "rgba(0, 0, 0, 0.6)",
                      color: "white",
                      padding: "10px",
                      fontSize: "calc(16px + 0.8vw)", // Smaller for mobile
                      textAlign: "center",
                      borderRadius: "10px 10px 0 0",
                      minWidth: "200px", // Shrinks width on mobile
                      maxWidth: "100%",
                      // WebkitBackdropFilter: "blur(10px)",
                      // backdropFilter: "blur(10px)",
                      borderRadius: "20px",
                      textShadow: "0 4px black"
                    }}
                  >
                    {/* Whitepaper Options */}
                  </h2>

                  {/* Buttons Below Title */}
                  <div 
                    style={{
                       boxShadow: "0 0 20px rgb(0, 0, 0)",
                      //  border: "1px solid #20B2A9",
                      display: "flex",
                      gap: "15px",
                      background: "rgba(0, 0, 0, 0.6)",
                      padding: "calc(3px + 0.2vw)",
                      // borderRadius: "0 0 10px 10px",
                      minWidth: "200px",
                      maxWidth: "100%",
                      justifyContent: "center",
                      flexWrap: "wrap", // Ensures buttons wrap on small screens
                        borderRadius: "20px",
                         WebkitBackdropFilter: "blur(10px)",
                      backdropFilter: "blur(10px)",
                     
                    }}
                  >
                    <button 
                      style={{
                        padding: "0px",
                        fontSize: "calc(8px + 0.5vw)", // Adjusts better on mobile
                        color: "white",
                        background: "transparent",
                        border: "none",
                        borderRadius: "20px",
                        cursor: "pointer",
                        transition: "0.3s",
                        whiteSpace: "nowrap",
                        // boxShadow: "0 4px black",
                       
                        minWidth: "120px", // Prevents too-large buttons on mobile
                         
                      }}
                    >
                      <a href="https://infinaeon.com/infwp.pdf" target="_blank" style={{ textDecoration: "none", color: "white" }}>
                        Download PDF
                      </a>
                    </button>

                    <button 
                      style={{
                        padding: "10px 15px",
                        fontSize: "calc(8px + 0.5vw)", // Adjusts better on mobile
                        color: "white",
                        // background:  "linear-gradient(black, transparent)",
                        // background:  "#20B2A9",
                        boxShadow: "0 4px black",
                     
                        border: "none",
                        // borderRadius: "5px",
                        cursor: "pointer",
                        transition: "0.3s",
                        whiteSpace: "nowrap",
                        minWidth: "120px", // Prevents oversized buttons
                          borderRadius: "20px"
                      }}
                    >
                      <a href="https://infinaeon.com/whitepaper" target="_blank" style={{ textDecoration: "none", color: "black"}} className="interactive" >
                        View Interactive
                      </a>
                    </button>
                  </div>
                </div>
              </div>

              {/* PresaleMethods component stays below the image */}
              <PresaleMethods />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProblemVideo;
